import React, { useEffect, useLayoutEffect } from "react";
import "./assets/fonts/fonts.css";
import "./styles/colors.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { PrivacyPolicyPage } from "./pages/privacyPolicy/privacyPolicy";
import { AppealPage } from "./pages/modalAppeal/appeal";
import { PartnershipPage } from "./pages/modalPartnership/partnership";
import { OptionModalPage } from "./pages/modalOption/option";
import { PageTemplate } from "./pages/template/template";
import { useGetRequest } from "./utils/api";
import styles from "./pages/template/template.module.scss";
import { isIOS } from 'react-device-detect';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { yandex } from "./utils/metriks";

const AppNavigation = () => {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  const { getRequest, GetRequestData, isLoading } = useGetRequest();

  useEffect( () => getRequest( "all" ), [] );

  useLayoutEffect( () => {
    if (
      location.pathname !== "/appeal" &&
      location.pathname !== "/partnership" &&
      location.pathname !== "/privacy-policy"
    )
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   //@ts-ignore
      //   behavior: "instant",
      // });
      window.scrollTo( 0, 0 );
  }, [ location.pathname ] );

  useEffect( () => {
    if ( location.hash !== "" ) {
      let elementId = location.hash.substring( 1 );

      const then = Date.now();
      const intervalId = setInterval( () => {
        if ( Date.now() - then > 400 ) clearInterval( intervalId );
        let element = document.getElementById( elementId );
        if ( element === null ) {
          return;
        }
        element.scrollIntoView( { behavior: "smooth" } );
        window.location.hash = "";
      }, 75 );
    }
  }, [ location.hash ] );

  useEffect( () => {
    yandex();
  }, [] )

  return (
    <>
      <Routes location={ previousLocation || location }>
        {/*<Route path={ "/ui-kit" } element={ <UiKitPage/> }/>*/ }

        <Route path={ "/privacy-policy" } element={ <PrivacyPolicyPage/> }/>
        <Route path={ "/appeal" } element={ <AppealPage/> }/>
        <Route path={ "/partnership" } element={ <PartnershipPage/> }/>
        <Route path={ "/option-modal" } element={ <OptionModalPage/> }/>

        { !isLoading &&
          GetRequestData &&
          GetRequestData?.pages.map( ( pageData: any ) => (
            <Route
              path={ pageData.route }
              element={
                <PageTemplate
                  pageData={ pageData }
                  menuData={ GetRequestData?.menu }
                  searchData={ GetRequestData?.search }
                />
              }
            />
          ) ) }
      </Routes>
    </>
  );
};

function App() {
  return (
    <div className={ isIOS ? styles.ios : 'other' }>
      <GoogleReCaptchaProvider reCaptchaKey="6LdV60EqAAAAAPcDPGo6B0HT4FntgCzOWvS1TgFL">
        <BrowserRouter>
          <AppNavigation/>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
